import React from 'react';
import PropTypes from 'prop-types';
import Common from './Common';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import '../../i18n';

const TemplateWrapper = ({ children, disableNavbar }) => (
    <Common>
      {!disableNavbar && <Navbar />}
      <div>{children}</div>
      {!disableNavbar && <Footer />}
    </Common>
);

TemplateWrapper.propTypes = {
  children: PropTypes.node,
  disableNavbar: PropTypes.bool,
};

export default TemplateWrapper;
